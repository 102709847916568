import React, { Component } from "react";
import {
  Route,
  NavLink,
  BrowserRouter,
  Switch
} from "react-router-dom";
import Home from "./Home";
import about from "./about";
import nf from "./nf";
import spbtv from "./spbtv";
import amediateka from "./amediateka";
import skemio from "./skemio";
import wispi from "./wispi";
import gifts from "./gifts";
import sber from "./sber";
import flo from "./flo";
import logo from "./logo";
import grid from "./grid";
import mobalytics from "./mobalytics";

class Main extends Component {
  render() {
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        
        <div id="main">
            <div class="header">
                <a href="/"><div class="logo"><h2>Anatoly Sidorov</h2></div></a>
                <div class="menu">
                    <NavLink exact to="/">Works</NavLink>&emsp;&emsp;
                    <NavLink to="/about">About</NavLink>
                </div>
            </div>
            <div className="content">
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/mobalytics" component={mobalytics}/>
                <Route path="/about" component={about}/>
                <Route path="/amediateka" component={amediateka}/>
                <Route path="/nf" component={nf}/>
                <Route path="/spbtv" component={spbtv}/>
                <Route path="/skemio" component={skemio}/>
                <Route path="/wispi" component={wispi}/>
                <Route path="/gifts" component={gifts}/>
                <Route path="/sber" component={sber}/>
                <Route path="/flo" component={flo}/>
                <Route path="/logo" component={logo}/>
                <Route path="/grid" component={grid}/>
            </Switch>
            </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default Main;