import React, { Component } from "react";
 
class skemio extends Component {
  render() {
    return (
      <div id="default-width">
        <div id="up_margin"></div>
        <center><h1>Skem.io</h1></center>
        <p>
        <img src="./images/skemio_01.png" class="img-900"/>
        

        

        The idea of ​​creating a platform for visual programming arose from a colleague for a long time. We discussed the prospects of such a project, how much they will be interesting to people. And after one of the discussions, we decided to try to create it!<br/><br/>


        <iframe width="900" height="506" src="https://www.youtube.com/embed/p1CNeVVFcu4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe><br/><br/>

The idea and programming was on the side of my colleague, I was responsible for creating the visual part and UX. The aim of the project was to create a tool for advanced users with which they could make their program / website. The idea was that there are a lot of site designers at the moment, but most often they are imprisoned for a certain framework and technology. We wanted to give the user a maximum of possibilities, both over the visual part and over the data.


Initially, we chose the graphic design approach. A successful concept has been made. In general, everything worked, it was cool. But at some point, we realized that you won’t go far on this. There were some restrictions on the code. It was decided to move in a different direction.
<img src="./images/skemio_02.png" class="img-900"/>
<img src="./images/skemio_03.png" class="img-900"/>
After about a year, the concept was approved and I started sketching and choosing a style for the platform. In parallel, a website was created on which we collected e-mails of users who were interested in our product. I was also actively engaged in promotion in social networks. I tried on myself in the role of a specialist in marketing :).
<img src="./images/skemio_04.png" class="img-900"/>
<img src="./images/skemio_05.png" class="img-900"/>
<img src="./images/skemio_06.png" class="img-900"/>
<img src="./images/skemio_08.png" class="img-900"/>



Unfortunately, due to some circumstances, my colleague and I decided to postpone the project until the best time. Participating in a startup gave me a lot of experience in managing time, it showed that sometimes it is necessary to fulfill different roles and be more flexible. It was a very valuable experience!
<img src="./images/skemio_07.png" class="img-900"/>
        </p>
        <div id="down_margin"></div>
      </div>
    );
  }
}
 
export default skemio;