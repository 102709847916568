import React, { Component } from "react";
 
class gifts extends Component {
  render() {
    return (
      <div id="default-width">
        <div id="up_margin"></div>
        <center><h1>Tango gifts</h1></center>
        <p>
        <center>
        <div id="unselect">

            <video width="375" height="415" loop="loop" autoplay="autoplay" muted="muted">
                <source src="video/amazing.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                <source src="video/amazing.webm" type='video/webm; codecs="vp9, vorbis"'/>
            </video>            
            </div>
            </center>
        As a product designer, you often face non-trivial tasks. One of these tasks was to create animated gifts in the application. On the one hand, the task does not seem to be very difficult - just make a beautiful animation. Difficulties arise when you need to make an animation that meets the requirements of the platform, business objectives, and constraints.
        <center>
        <div id="unselect">

            <video width="380" height="380" loop="loop" autoplay="autoplay" muted="muted">
                <source src="video/hearts-arrow.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                <source src="video/hearts-arrow.webm" type='video/webm; codecs="vp9, vorbis"'/>
            </video>            
            <video width="380" height="380" loop="loop" autoplay="autoplay" muted="muted">
                <source src="video/elf.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                <source src="video/elf.webm" type='video/webm; codecs="vp9, vorbis"'/>
            </video>
            </div>
            </center>
I believe that the ability of a designer to work with different tools and be able to solve tasks is one of the most important skills of a product designer.

At the very beginning, we sat down with the developers and managers and thought about what gifts should be and what restrictions should be so that later there would be no problems.

For the presentation of the final picture and subsequent export, I used After Effects. Next, I exported all the data to json and compiled the final archive.<br/><br/>
<img src="./images/tango_gifts_01.png" class="img-900"/>
<p>An important requirement for animated gifts was the size of the final archive. The most “lightweight” option was to create an animation with several pictures, and the animation itself should be implemented with 4 properties: position, rotation, transparency and scale. The most "heavy" option was to create frame-by-frame animation. Since in the requirements it was agreed to make an animation 30 frames per second, it is easy to calculate that if each frame of the animation weighs, for example, 50 kb, a 5 second clip will weigh approximately 7.3 megabytes. Keeping in mind that the animation of the gift is loaded by the client - these are very large numbers.</p>
<img src="./images/tango_gifts_02.png" class="img-900"/>

In such a situation, it is necessary to maintain a balance and combine “lightweight” and “heavy” animation to achieve an acceptable result. For an important animated gift, we could afford to use "heavy" animation, and for less - "lightweight".<br/><br/>

Sometimes quite complex ideas (piercing, overflowing) can be achieved at a low price, if you look a little. Below is a 3D scan of how the animation of piercing the arrow of the cupid of hearts was made.
<center>
<div id="unselect">

<video width="375" height="375" loop="loop" autoplay="autoplay" muted="muted">
    <source src="video/3d_gift.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
    <source src="video/3d_gift.webm" type='video/webm; codecs="vp9, vorbis"'/>
</video>            
</div>
</center>

Sometimes I had to use 3D max to realize ideas, in this case I created a 3D model, rendered it, then exported everything to After Effects.
<center>
<div id="unselect">
            <video width="380" height="380" loop="loop" autoplay="autoplay" muted="muted">
                <source src="video/bugatti.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                <source src="video/bugatti.webm" type='video/webm; codecs="vp9, vorbis"'/>
            </video>        
            <video width="380" height="380" loop="loop" autoplay="autoplay" muted="muted">
                <source src="video/diamond.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                <source src="video/diamond.webm" type='video/webm; codecs="vp9, vorbis"'/>
            </video>   
            </div>
            </center>
        </p>
      </div>
    );
  }
}
 
export default gifts;