import React, { Component } from "react";
 
class logo extends Component {
  render() {
    return (
      <div id="default-width">
        <div id="up_margin"></div>
        <center><h1>Logo & Marks</h1></center>
        <p>
        

<center>
<img src="./images/l_tango.png" id="img-logo"/>
<img src="./images/l_wispi.png" id="img-logo"/>
<img src="./images/l_solidus.png" id="img-logo"/>
<img src="./images/l_wireknot.png" id="img-logo"/>
</center>



</p>
        </div>
    );
  }
}
 
export default logo;