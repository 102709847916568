import React, { Component } from "react";
 
class amediateka extends Component {
  render() {
    return (
      <div id="default-width">
        <div id="up_margin"></div>
        <center><h1>Amediateka</h1></center>
        <p>
        One of the clients of SPB TV was Amedia - the Amediateka project. My task was to develop a design for the application for Windows Phone and Windows Tablet.
        <img src="./images/amediateka_01.png" class="img-900"/>
Since at the time of work Amediateka had his own corporate identity - this slightly eased my task. I concentrated on creating a native UX for users.

I spent some time exploring the principles, guidelines, and best practices for the Windows mobile platform. After that, together with the project manager, we collected all the functional requirements that should have been in the mobile application.

Initially we decided to use the native elements of the platform (metro style, fonts, errors, icon style). Meanwhile, I really wanted to leave the Amediateka style - I planned to take it from the web (colors, composition, company elements) so that users who are using the service on the web do not have a dissonance when switching to the application.
    <img src="./images/amediateka_02.png" class="img-900"/>
After defining the concept and general direction, sketches and low fidelity mockups were designed. When they were approved by the team and the customer, I began to work on the details and states screens. For better understanding of big picture I've also created scheme with user flow. This allowed team to see some issues and mistake in logic.
<img src="./images/amediateka_03.png" class="img-900"/>

In some cases, for a better understanding of how a particular state or functionality works - I had to make functional presentations. For this, I used After Effects. It was something new in the company, so it took some time for me to work on it. But It was totally worth it - me and managers could decide and play around with this and that to take a decision. It saved development time alot.
<img src="./images/amediateka_04.png" class="img-900"/>
After the design for Windows Phone was presented and approved, I started working on Windows Tablet.
<img src="./images/amediateka_05.png" class="img-900"/>
<img src="./images/amediateka_06.png" class="img-900"/>
<img src="./images/amediateka_07.png" class="img-900"/>
Of course, working as a team with managers and developers gave me great experience in understanding the processes of developing a mobile application. However, I was very interested to understand the concept of Metro adopted in Windows Phone.

        </p>
        <div id="down_margin"></div>
        </div>
    );
  }
}
 
export default amediateka;