import React, { Component } from "react";
 
class nf extends Component {
  render() {
    return (
      <div id="default-width">
        <div id="up_margin"></div>
        <center><h1>Nash Football</h1></center>
        <p>
        <img src="./images/nf_01.png" class="img-900"/>
Nash football (means "Our football" from Russian) online is the TV channel of the Russian Premier League football. Their representatives wanted to expand our audience on mobile applications.

The task was to create a website on which the user can quickly begin to solve his main problem - watching a football match.

After receiving all the requirements, several concepts were suggested, from which the customer chose the most suitable option. Further, based on the selected concepts, in the process of developing the main screens.
<img src="./images/nf_02.png" class="img-900"/>
Also, the area of my responsibility included the development of application design for the iOS client. After a successful presentation of the design of the application to the customer, it was decided to begin design development for the iPad.
<img src="./images/nf_03.png" class="img-900"/>
<img src="./images/nf_04.png" class="img-900"/>
After that, my task was to finalize the design for the website and create design for the smart TV.
<img src="./images/nf_05.png" class="img-900"/>
This was my first big project, in which I take part as a UX/UI designer. Although much was not clear to me along the way - thanks to the excellent team that supported and trained along the way, I managed to meet the deadlines and make a successful product.
</p>
        <div id="down_margin"></div>
        </div>
    );
  }
}
 
export default nf;