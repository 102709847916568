import React, { Component } from "react";
 
class about extends Component {
  render() {
    return (
      <div id="default-width">
        <div id="up_margin"></div>
        <div id="table">
          <div id="me"></div>
          <div id="text">
          {/* <h4>About Me</h4> */}
          <p>Hello, my name is Anatoly Sidorov. Solving problems with design tools is what I love about my work. Everyday is not the same. I love what I'm doing and it's never going to end!<br/><br/>
          
          Don't hesitate to send me a friendly "Hi 👋" <a href="mailto:apegrave@gmail.com">apegrave@gmail.com</a></p><br/>
          <div id="table">
          
            <a class="a_tab" href="./files/cv_asidorov.pdf" target="blank"><div id="button_left">CV</div></a>
            <a class="a_tab" href="https://www.linkedin.com/in/apegrave/" target="blank"><div id="button_left">LinkedIn</div></a>
            <a class="a_tab" href="https://www.behance.net/apegrave" target="blank"><div id="button_left">Behance</div></a>
            <a class="a_tab" href="https://dribbble.com/apegrave" target="blank"><div id="button_left">Dribbble</div></a>

          </div>
          
          </div>
        </div>
      </div>
    );
  }
}
 
export default about;