import React, { Component } from "react";
 
class mobalytics extends Component {
  render() {
    return (
      <div>
        <div id="default-width">
        
        <div id="m_divider"></div>
        <div id="m_block" class=" gap-16">
          <p id="m_h3" style={{color: "#888888"}}><a target="_blank" href="https://mobalytics.gg/">Mobalitycs</a></p>
          <p id="m_h1">League Of Legends: Gamer Performance Index (GPI)</p>
          {/* <p id="m_body">GPI as a system for identifying your game and pumping your skills in the game</p> */}
        </div>
        <div id="m_divider"></div>
        <div id="m_divider"></div>

        <div id="m_block_01" class="gap-40">
          <div id="m_block" class="gap-40">
            <div id="m_block" class="gap-20">
              <p id="m_h2">Challenge</p>
              <p id="m_body">Relaunch the core feature so that it starts working together with the rest functions of the product.</p>
            </div>
            <div id="m_block" class="gap-20">
              <p id="m_h2">Solution</p>
              <p id="m_body">In the course of the work, the current user experience and the accumulated problems were revised. GPI ceased to be a feature in itself, a step was taken towards “GPI - function” - an ongoing tool with clear action points to achieve goals.</p>
            </div>
          </div>
          <img width="440px" height="520px" src="./images/mob_01.png"/>
        </div>
        <div id="m_divider"></div>
        
        <div id="m_block_01" class="gap-40">
          <img width="380" height="271px" src="./images/mob_02.png"/>
          <p id="m_body" style={{fontStyle: "italic", color: "#888888"}}>The philosophy of Mobalitycs is that every person who works in the company understands for whom and why we are making the product. Ideally, the person should be a gamer of the game you are working on. Then there is a higher chance that you will be able to offer some kind of solution, whether you are a designer, engineer or a data analyst. This allows you to work more efficiently and with more interes.<br/><br/>

I was lucky enough to work on one of the main feature of the product - Gamer Performance Index (GPI). And even a little more!</p>
        </div>
        <div id="m_divider"></div>

        <div id="m_block_01" class="gap-40">
          <div id="m_block">
            <div id="m_block" class="gap-20">
              <p id="m_h2">Process</p>
              <p id="m_body">Аfter initial acquaintance with the task, we arranged a session with the co-founder and game experts, in which they told us the story, current problems and the potential output / vision that is expected from the feature. The first step <em>(1)</em> after that was the research: I ​​looked at the current implementation, studied user feedback on the current version of the GPI, together with PM we’ve had few sessions with stakeholders, competitor analysis was made.<br/><br/>

After collecting all the initial data <em>(2)</em>, toghether with team we’ve started brainstorming to find new opportunities to increase the value of the feature and achieve the set goals. After that, we (3) made a concept to test our ideas within the company with experts and colleagues.<br/><br/>

We worked <em>(4)</em> in sprints that allowed us to make the right decisions in a timely manner and receive important feedback from colleagues, taking into account the set deadlines.<br/><br/>

As a result <em>(5)</em> we not only made the GPI, but also worked out other functionality that indirectly was related to the GPI: new dashboard, comparisons, challenges page and report. </p>
            </div>
          </div>
          <div id="m_block" class="gap-8">
            <div id="up_margin"></div>
            <img width="400px" height="410px" src="./images/mob_03.png"/>
            <center><p id="m_italic">Trundle. One of my favorite champion in League of Legends: Bold, Cocky, Funny!</p></center>
          </div>
        </div>
        <div id="m_divider"></div>

        <div id="m_block">
          <center><img width="877" height="330" src="./images/mob_04.png"/></center>
        </div>
        <div id="m_divider"></div>

        <div id="m_block" class="m_i_600 gap-20" >
              <p id="m_h2">GPI</p>
              <p id="m_body" width="600px">The Gamer Performance Index (GPI) uses in-game data to analyze and improve player performance across several critical areas of competitive gaming. While you focus on playing, we gather all your data and crunch it through our machine learning algorithms in order to give you a multifaceted assessment of your game.</p>
        </div>
        <div id="m_divider"></div>

        <div id="m_block_01" class="gap-40">
          <img width="440px" height="520px" src="./images/mob_05.png"/>
          <div id="m_block" class="gap-40">
            <div id="m_block" class="gap-8">
              <p id="m_h3">Interview</p>
              <p id="m_body">Sessions were held with experts, work colleagues and founders to better understand expectations and bottlenecks.</p>
            </div>
            
            <div id="m_block" class="gap-8">
              <p id="m_h3">User’s feedback</p>
              <p id="m_body">Mobalytics constantly keeps his finger on the pulse by receiving feedback through various communication channels and analyzes the data received.</p>
            </div>
            
            <div id="m_block" class="gap-8">
              <p id="m_h3">Immersion in the product</p>
              <p id="m_body">For a better understanding of the feature and the search for possible growth points, independent use of the features has become an important aspect.</p>
            </div>
          </div>
        </div>
        <div id="m_divider"></div>

        <div id="m_block">
          <p id="m_h2">Which led to the following observations</p>
          <div id="m_divider"></div>
          <center><img width="880" height="372" src="./images/mob_06.png"/></center>
        </div>
        <div id="m_divider"></div>

        <div id="m_block_01" class="m_i_600_v gap-40">
          <p id="m_h2">From our observations and communication channels, we concluded</p>
          {/* <div id="m_divider"></div> */}
          <div id="m_block" class="gap-8">
              <p id="m_h3">Limited functionality</p>
              <p id="m_body">Although the product evokes positive emotions among users, there is a lack of depth of interaction with the functionality. There is no way to interact with the functionality.</p>
          </div>
          <div id="m_block" class="gap-8">
              <p id="m_h3">Audience specificity</p>
              <p id="m_body">GPI is definitely not for all players. The uniqueness of the approach has not yet been fully studied, we can say that this is an experimental feature that is interesting for beginners or teams.</p>
          </div>
        </div>
        <div id="m_divider"></div>

        <p id="m_h2">Analysis</p>
        <div id="m_divider"></div>

        <div id="m_block" class="gap-40">
            <div id="m_block" class="gap-8">
              <p id="m_h2" class="m_i_180">Opportunity</p>
              <p id="m_body" class="m_i_180 m_i_480">After the research, we began to form an understanding in which direction the feature should develop. After discussion, we came up with a concept.</p>
            </div>
            <p id="m_h4">If the GPI is a unique fingerprint of the player, we need to give the opportunity to develop any of the existing skills.</p>
            <div id="m_block">
              <p id="m_body" class="m_i_180 m_i_480">We wanted to create a system in which the user will return to the GPI over and over again, checking their progress and interacting with the system and other players</p>
            </div>
        </div>
        <div id="m_divider"></div>

        <center><img width="880" height="310" src="./images/mob_07.png"/></center>
        <div id="m_divider"></div>

        
        <div id="m_block" class="m_i_480 gap-40">
              <p id="m_h2">Design Sprints</p>
              <p id="m_body">When we evaluated the direction, functional requirements for the new GPI, and the work plan, we got down to work. According to the available data, we understood that the new GPI should be understandable and useful for novice players who want to improve their game.</p>
          </div>
          <div id="m_divider"></div>

          <center><img width="880" height="182" src="./images/mob_08.png"/></center>
          <div id="m_divider"></div>

          <div id="m_block" class="gap-40">
          <div id="m_block" class="m_i_600 gap-40">
              <p id="m_h2">User Testing</p>
              <p id="m_body">After the first low-fidelity prototype was ready, we decided to test the direction and design of the solution internally. We received a lot of feedback, which at times changed our vision and priorities.</p>
          </div>
          <p id="m_h4">At about this point, we realized that we wanted to fit too much on one screen.</p>
          <div id="m_block" class="m_i_600 gap-40">
              <p id="m_body">Therefore, it was decided how to say "eat the elephant piece by piece." On the main screen of GPI we decided to focus on the current level of player and challenges. The rest of the functionality such as: reworking the main screen, GPI comparisons, challenges had to go to separate pages.<br/><br/>

When developing extraordinary functionality, dry wireframes do not always help to understand how good the final result will look. It was a difficult test for me and the team - I had to do a lot in the almost final version and, of course, not everything worked out the first time.</p>
          </div>
          </div>
          <div id="m_divider"></div>

          <center><img width="880" height="622" src="./images/mob_09.png"/></center>
          <div id="m_divider"></div>
          
          <div id="m_block" class="m_i_480 gap-40">
              <p id="m_h2">Result</p>
              <p id="m_body">After several iterations of edits and validations, we prepared a design that, on the one hand, solved the user's tasks, and, on the other hand, solved the accumulated business problems with an emphasis on further development.</p>
          </div>
          <div id="m_divider"></div>
          </div>

          <div id="m_block" class="bg-blue">
          <div id="m_divider"></div>
            <center>
              <img width="1400" height="2641" src="./images/mob_16.png"/><div id="m_divider"></div>
              <img width="1400" height="4363" src="./images/mob_15.png"/><div id="m_divider"></div>
            </center>
          </div>
          
        </div>
    );
  }
}
 
export default mobalytics;