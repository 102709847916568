import React, { Component } from "react";
import {
  Link
} 
from "react-router-dom";
class Stuff extends Component {
  render() {
    return (
        
<div id="container">

<h1>Anatoly is a Lead Designer <b>@EPAM</b>, creating<br/> solutions for millions of users around the world!</h1>
                    < Link to="/mobalytics"><div class="el_01" id="moba"></div></Link>
                    < Link to="/logo"><div class="el" id="logo"></div></Link>
                    < Link to="/wispi"><div class="el" id="wispi"></div></Link>
                    < Link to="/spbtv"><div class="el" id="spbtv"></div></Link>
                    < Link to="/gifts">
                    <div class="el" id="tango_video">
                        <video width="380" height="380" poster="video/duel.jpg" loop="loop" autoplay="autoplay" muted="muted">
                            <source src="video/gifts_preview.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                            <source src="video/gifts_preview.webm" type='video/webm; codecs="vp9, vorbis"'/>
                        </video>
                    </div>
                    </Link>
                    < Link to="/nf"><div class="el" id="nf"></div></Link>
                    <Link to="/amediateka"><div class="el" id="amediateka"></div></Link>
                    <Link to="/skemio"><div class="el" id="skemio"></div></Link>
                 </div>
    );
  }
}
 
export default Stuff;