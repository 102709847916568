import React, { Component } from "react";
 
class spbtv extends Component {
  render() {
    return (
      <div id="default-width">
        <div id="up_margin"></div>
        <center><h1>SPB TV Russia</h1></center>
        <p>
        <h3>01. Overview</h3>
        To improve the quality and more efficient development of the head platform, the company decided to create its own product. Together with the team, I was able to participate in its creation. Since time allowed - I proposed to approach the design process comprehensively and do research.
        <div id="info">
          <center>
          
          <i><h4>"Free online service for watching TV channels and movies in high quality."</h4></i>
          <h5>from website</h5>
          </center>
        </div>
        
        <h3>02. Research</h3>
First of all we decided to determine business and user goals for the project. Next, we identified our target audience (TA). It was important for us to collect the maximum amount of information.<br/><br/>
<img src="./images/spbtv_01.png" class="img-900"/>

1. Age<br/>
2. Gender<br/>
3. Education<br/>
4. How much they spend on paid TV channels?<br/>
5. Occupation?<br/>
6. How users spend their free time?<br/>
7. What communication channel do they use to receive information more often?<br/>
8. What platform do they use more often when watching TV?<br/>
9. What time do they use our services more often?<br/>
10. what is the lifestyle of our users<br/>
11. What device they are using when watching TV (mobile/laptop/Smart TV)?<br/>
12. What category of content our users like most?<br/>
13. etc.<br/><br/>

After that, we examined the competitors, what functionality they have, what they have, what we don’t have. Our marketers collected information on the functionality that we needed to have in our product. We also collected information from user reviews in our applications to understand what difficulties our users face.<br/><br/>

        <h3>03. Process</h3>
After we received all the possible information, we were able to understand who our target audience really is. After that, we started creating user persona. For each person, we identified the tasks-problems-solutions that he faces.We used the personas further in our joyrney  map. At this stage, we tried to identify errors in the logic, prioritize and improve our solutions.<br/>

<img src="./images/spbtv_02.png" class="img-900"/>
After that, we gathered as a team and based on all of the above, started brainstorm. As a result, we have created a mind map.  After that, we put together a project scheme with the main pages which allowed us to work on wireframes.
<img src="./images/spbtv_03.png" class="img-900"/>
<img src="./images/spbtv_04.png" class="img-900"/>
        <h3>04. Website wireframes</h3>

        <img src="./images/spbtv_08.png" class="img-900"/>
        <img src="./images/spbtv_09.png" class="img-900"/>
        <img src="./images/spbtv_10.png" class="img-900"/>

        <h3>05. Design outcome</h3>
        <img src="./images/spbtv_06.png" class="img-900"/>  
        <img src="./images/spbtv_07.png" class="img-900"/>
        
        <img src="./images/spbtv_05.png" class="img-900"/>

</p>
        <div id="down_margin"></div>
        </div>
    );
  }
}
 
export default spbtv;