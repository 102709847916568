import React, { Component } from "react";
 
class sber extends Component {
  render() {
    return (
      <div id="default-width">
        <div id="up_margin"></div>
        <center><h1>Тестовое задание</h1></center>
        <p>
        <h3>01. Обзор</h3>
        Перед тем как приступить к работе я всегда выполняю изучение по теме. Это важно как для более объективного понимания задачи, так и для общего кругозора о сфере. Плюс немаловажный факт - никогда не знаешь, когда та или иная информация окажется важной\ключевой\определяющей особенно в таких комплексных темах на стыке технологий.<br/><br/>

        Будучи сам водителем и почитав\подумав по теме - понимаешь, что тема действительно колоссальная, затрагивает целые пласты наук: <br/>

        1. Автопром - это автомобили в целом и непосредственно устройства управления - физические объекты и аппаратные возможности.<br/>
        2. Человек и поведенческая психология его в динамике использования автомобилем - управление, реакции на внутренние и внешние сигналы, привычки, опыт вождение и т.п.<br/>
        3. Технологии в разрезе возможностей применения - приложения, сервисы, мультимедия, датчики, синхронизация.<br/><br/>

        В силу временных рамок погрузится достаточно глубоко не представлялось возможным, поэтому пришлось “пройтись по верхам”, вот некоторые статьи, которые хотелось бы отметить отдельно:

        <a href="https://vc.ru/design/74497-sozdanie-dizayna-yandeks-avto">Яндекс Авто</a> - интересная статья, которая пролила свет на процесс работы и показала один из подходов к работе<br/>
<a href="https://medium.com/@autoustwo/the-near-future-of-in-car-hmi-5b34a76fc7a">HMI</a> - серия статей про HMI, с небольшим экскурсом в историю и проблемы, которые возникают при в работе<br/>
<a href="https://arxiv.org/pdf/1805.02787.pdf">Tesla</a> - минимализм в HMI (Tesla)


        
        <h3>02. Уточнение и установление границ задачи</h3>
        На этом этапе важно понять рамки задачи и стараться не выходить из них. Еще раз прочтя задание можно провести анализ для выявления дополнительных условий и наблюдений.


<img src="./images/sber/002.png"/>

В процессе работы было стойкое ощущение нехватки информации для полного погружения в задачу, которая может получена из совершенно разных уголков продукта, например: техническая документация, аппаратные возможности, ЦА, бизнес задачи, метрики, предыдущий опыт, частые ошибки, отзывы и инсайты. Визуально это можно представить так.

<img src="./images/sber/001.png"/>

Непосредственное взаимодействие происходит между 3 объектами, каждый из которых имеет элементы управления: человек, руль и ГУ. В рамках ТЗ необходимо определить границы возможностей каждого из объектов.<br/><br/>


<b>Водитель</b> - так как водитель едет (основной фокус внимания на дороге) область возможностей взаимодействия с элементами управления для выполнения целевого действия сужается до голосовой команды, физическим взаимодействием рукой с тачскрином или комбинации двух.<br/>
<b>Руль</b> - имеет кнопки на руке + кнопка вызова голосового ассистента. Можно сделать вывод, что водитель может взаимодействовать с рулем только руками для выполнения целевого действия. Элементы управления руля могут выступать в роли автономного управления или в связке с ГУ.<br/>
<b>ГУ</b> - обладает бóльшим спектром возможностей чем руль. Взаимодействие с водителем возможно голосом (вызывается кодовым словом) и руками (управление через тачскрин)<br/><br/>


Дополнительные выводы\предположения, которые можно сделать из ТЗ:<br/>
<ul>

<li>1. Меню ГУ должно иметь как минимум 4 категории на тачскрине:
  <ol type="a">
    <li>Навигатор</li>
    <li>Радио</li>
    <li>Медиа</li>
    <li>Голосовой ассистент</li>
  </ol></li>
  <li>2. Условие того, что водитель залогинен в стриминговом сервисе намекает, что стриминговый сервис является сторонним, поэтому доступ к управлению контентом внутри приложения через голосовое управление возможно будет ограничен. Т.е. выбрать голосом нужный плейлист будет невозможно.<br/>
  </li><li>3. Кнопки управления на руле могут выполнять роль “дополнения” к тачкрину (подтверждение, скролл, выбор). Например: меню -> выбор медиа -> выбор категории “плейлисты” -> выбор плейлиста “русский рок”.<br/>
  </li><li>4. Так как основная функция, которая выбрана по умолчанию - навигатор, стоит учитывать возможность выполнения целевого действия в режиме мультизадачности. Тут возможны вариаций в зависимости от аппаратных возможностей системы:<br/>
<ol type="a">
<li>разделение экрана на две рабочие зоны - навигатор и выбор плейлиста (split screen)</li>
<li>“перекидывания” навигатора на приборную панель</li>
<li>частичное перекрытие экрана навигатора выбором плейлиста (overlay)</li>
</ol>
</li>
</ul>

На вышеописанных данных, предположениях и выводах можно построить предварительную схему взаимодействия водителя для выполнения целевого действия.

<img src="./images/sber/003.png"/>

<h3>03. Исследование</h3>

В рамках тестового задания создание прото персон кажется не совсем целесообразным, так как мы рассматриваем очень конкретный случай и мотивы с целями не очень важны -> задача определена. Тем не менее даже тут мне кажется важным рассмотреть несколько сценариев пользовательского поведения.<br/><br/>

К рассмотрению я выбрал следующие сценарии:<br/>
<ul>
  <li>1. Водитель выполняет целевое действие через голосового помощника</li>
  <li>2. Водитель выполняет целевое действие через тачскрин</li>
</ul>
<a href="./images/sber/004.png" target="blank"><img src="./images/sber/004.png"/></a>
<a href="./images/sber/005.png" target="blank"><img src="./images/sber/005.png"/></a>

Карта сервиса позволила подробнее взглянуть на пошаговый этап взаимодействия и выявить проблемные моменты и возможности улучшения. Далее я перешел к наброскам и эскизам.

<h3>04. Дизайн скетчи</h3>

На этом этапе просмотрел я продолжил поиск рефов и изучение интересных решений и концепты, которые можно было бы использовать.
<a href="./images/sber/008.png" target="blank"><img src="./images/sber/008.png"/></a>

Так как UI/UX сильно завязан на физические размеры устройства экрана  (а так же размер и соотношение сторон), аппаратные возможности и предопределенную стилистику - было принято решение ограничится скетчами с низкой точностью. Так же в рамках тестового задания я пренебрег всеми возможными сценариями (ошибки, подтверждения, дублирование и т.п.), оставив только позитивный кейс. <br/><br/>

После проведенного исследования и изучения тенденций в уже созданных устройствах - становится понятным, что помимо меню и основного экрана - необходимо дополнительный элемент, который будет “наезжать” или “выезжать” на экран для выполнения второстепенных задач без отрыва от выполнения главной задачи. 
<br/><br/>

Логика расположения меню на экране слева (для леворуких машин) обусловлена близостью для взаимодействия в первую очередь с водителем. По той же причине кажется логичным расположить второстепенное окно слева. 

<a href="./images/sber/006.png" target="blank"><img src="./images/sber/006.png"/></a>
<a href="./images/sber/007.png" target="blank"><img src="./images/sber/007.png"/></a>

<h3>05. Клибабельный прототип</h3>
Сделать кликабельный прототип используя sketch/figma показалось мне немного тривиальным, поэтому я решил выполнить его в framer studio. Это позволило поработать с анимацией в прототипе. Для презентации я так же исопльзовал Adobe After effects для того чтобы презентация была не такой скучной.
<br/><br/>
<video width="900" height="675" loop="loop" controls="true" autoplay="autoplay" muted="muted">
            <source src="video/sber_01.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            <source src="video/sber_01.webm" type='video/webm; codecs="vp9, vorbis"'/>
</video>  
<br/><br/>
Так же добавлю видео как протитп выглядит в коде. К сожалению выгрузить протитип в веб не получилось - какие-то проблемы с облычным сервисом у Framer.
<a href="./images/sber/007.png" target="blank"><img src="./images/sber/009.png"/></a>
<video width="900" height="564" loop="loop" controls="true" autoplay="autoplay" muted="muted">
            <source src="video/sber_02.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            <source src="video/sber_02.webm" type='video/webm; codecs="vp9, vorbis"'/>
</video>  
<br/><br/>

</p>
        <div id="down_margin"></div>
        </div>
    );
  }
}
 
export default sber;