import React, { Component } from "react";
 
class wispi extends Component {
  render() {
    return (
      <div id="default-width">
        <div id="up_margin"></div>
        <center><h1>Wispi</h1></center>
        <p>

        <h3>01. Overview</h3>
        <img src="./images/wispi_08.png" class="img-900"/>
        Since the application belongs to the B2C segment, a very important aspect is the external presentation of the application. Together with the UI, it was necessary to propose and implement the latest patterns and practices. Also, in the absence of corporate identity, it was necessary to define and propose solutions that would create a holistic view of the application as a product.



        <div id="info">
          <center>
          
          <i><h4>"Our aim is to connect and promote communication between Wispi users FREE where possible and offer users excellent alternatives to the current phone / communication operator."</h4></i>
          <h5>from website</h5>
          </center>
        </div>
        <img src="./images/wispi_09.png" class="img-900"/>
        <h3>02. Process</h3>
        
        Since I started work on the project not from scratch, I had to dive into the application with my head, to become an experienced user of this application. To do this, I studied all the screens and made a scheme of the main screens for further work, tried all the functions and wrote them down in a separate place. After that, I had a number of conversations with stakeholders to understand the target audience, company values ​​and applications, what they expect from the application, what goals the product pursues and what tasks the teams will focus on in the near future.<br/><br/>

        After I began to develop an idea of ​​the product and my role in the project, I began to research. For starters, I studied direct and non-direct competitors in the market. After analysis, together with the team, we tried to find functions that we could use in our application. We also looked at user reviews, tried to find sore pain in the application and features.

        <img src="./images/wispi_10.png" class="img-900"/>
        
        <div id="info">
        <center><i><h4>
        "it is better to have a bad plan than no plan"

        </h4></i>
        <h5>Garry Kasparov</h5>
        </center>
        </div>
        
         
        Only after that I was able to understand what I would have to work with, what specific advantages it had, what pain points are in the application, what kind of TA we have, what goals and prospects we have. I collected all the information and shared it with stakeholders. This was done for 2 reasons:<br/><br/>
        1. <b>Double Check</b> - it's always important for me to understand whether I made the right conclusions or not.<br/>
        2. <b>Transparency</b> - working in a cross-functional, decentralized team forces you to take additional attention to keep things open and clear.<br/><br/>
        
        After that, together with the team including BA, CEO, CTO and managers, we drew up a plan of my work. This plan contained tasks for the short and long term goals of the project.
        
        <h3>03. Insights & Opportunities</h3>

        In the course of my research, several opportunities and pain spots in the application were identified that we needed to pay special attention to. They were added to my work plan:

        <img src="./images/wispi_11.png" class="img-900"/>
        
        <h3>04. Workflow</h3> 

        The work was built on sprints, so usually my part of the work was done before the team members did it. Most often, I received technical documentation of the assignment, according to which I drew a diagram of interaction and screens by hand. This was followed by the creation of frameworks and interaction. After that, I showed to stakeholders, received feedback. Next, I polished screens and transitions, often created prototypes to check my theories. After completion, I presented the design for the development team and prepared the necessary assets.<br/><br/>

        Upon completion of development, I performed design validation.
        <img src="./images/wispi_12.png" class="img-900"/>
        <h3>05. Feature wireframes</h3> 

        <img src="./images/wispi_17.png" class="img-900"/>



        <h3>06. Design outcome</h3>
        <center>
        <div id="unselect">

        
        
        <video width="900" height="506" loop="loop" controls="true" autoplay="autoplay" muted="muted">
            <source src="video/wispi_features.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            <source src="video/wispi_features.webm" type='video/webm; codecs="vp9, vorbis"'/>
        </video>            
        </div>
        </center>

        <img src="./images/wispi_13.png" class="img-900"/>
        <img src="./images/wispi_02.png" class="img-900"/>
        <img src="./images/wispi_05.png" class="img-900"/>
        <img src="./images/wispi_14.png" class="img-900"/>
        <img src="./images/wispi_15.png" class="img-900"/>
        <img src="./images/wispi_16.png" class="img-900"/>
<center>
<iframe src="https://player.vimeo.com/video/302696257" width="400" height="696" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
<iframe src="https://player.vimeo.com/video/302696330" width="400" height="600" frameborder="0" allowfullscreen></iframe>
</center>
        </p>
        <div id="down_margin"></div>
      </div>
    );
  }
}
 
export default wispi;