import React, { Component } from "react";
 
class grid extends Component {
  render() {
    return (
      <div id="default-width">
        <div id="up_margin"></div>
        <center><h1>Тестовое задание</h1></center>
        <p>
        <h3>01. Обзор</h3>
        Тестовое задание состоит в разработке дизайна для работы двух специалистов в системе.  Исходные данные предоставляют собой животных, у которых есть атрибуты и специалисты, которые взаимодействуют с ними видоизменяя эти атрибуты. Также специалисты могут создать новых животных. У каждого из специалистов есть свои особенности, которые необходимо учитывать при разработке дизайна. 


        
        <h3>02. Анализ</h3>
        Специфика и характер задач специалистов предопределяет требования к платформе . В данном задании я определил 2 сущности: вводные данные и пользователи, которые должны с ней взаимодействовать. За основу представления вводных данных я выбрал таблицу. Характер работы с этими данными (периодичность, сегментация, совместная работа, элементы отслеживание) добавил дополнительный слой взаимодействия в таблицу, которая позволит в полной мере выполнять поставленные задачи с максимальной эффективностью. Определение ключевых моментов в специфике работы специалистов помогло понять с какими сложностями могут столкнуться пользователи и приступить к их решению:<br/><br/>


1. Необходимость визуальной индентификации о том, кто последний раз взаимодействовал с тем или иным животным.<br/>
2. Необходимость следить за определенным типом данных (Джо делает цвет большинства животных красным, Мо иногда меняет хвост и цвет).<br/>
3. Возможность отслеживать определенную категорию данных в виде таймера (Джо выводит животных гулять раз в день).<br/>
4. Необходимость задавать определенный таймер для категории данных (Мо меняет носы и шерсть раз в 2 недели).<br/><br/>

<a href="./images/grid/art-01.png" target="blank"><img src="./images/grid/art-01.png"/></a>
<a href="./images/grid/art-02.png" target="blank"><img src="./images/grid/art-02.png"/></a>
Процесс работы\размышления:<br/><br/>
<video width="900" height="564" loop="loop" controls="true" muted="muted">
            <source src="video/grid-process.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
</video>  

<h3>03. Дизайн</h3>

На этом этапе у меня было понимание того с каким видом данных нужно работать, какие требования имеются к платформе, поэтому я приступил к скетчам. Для начала я сделал эскиз таблицы с данными, Далее для решения задач, с которыми сталкиваются специалисты я начал искать приемлемое решение на уровне пользовательского интерфейса

<a href="./images/grid/ui-01.png" target="blank"><img src="./images/grid/ui-01.png"/></a>
<a href="./images/grid/ui-02.png" target="blank"><img src="./images/grid/ui-02.png"/></a>

<br/><br/>

</p>
        <div id="down_margin"></div>
        </div>
    );
  }
}
 
export default grid;